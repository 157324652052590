import React from "react"
import { PageProps } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import SEO from "../../components/seo"
import { GridContainer } from "../../components/grid"
import { PhotoHero, SimpleTitle } from "../../components/heros"
import {
  CardsContainer,
  SimpleCard,
  SecondaryCard,
} from "../../components/cards"
import { NavigationDetailCTA } from "../../components/ctas"
import { PrimaryLink } from "../../components/links"

const Card = styled(SimpleCard)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 7;
  }
`

const Paragraph = styled.p`
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledCardsContainer = styled(CardsContainer)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 7;
  }
`

const DiverseInclusionPage: React.FC<PageProps> = () => (
  <>
    <SEO
      title="Diversiteit &amp; Inclusie"
      description="Organisaties willen wel, maar weten vaak niet hoe ze aan de slag moeten met D&amp;I. De Beukelaar Groep kan jou en je organisatie ondersteunen bij de eerste stappen in dit proces. Door middel van een organisatieanalyse stellen we een punt nul op en adviseren wij jullie over oplossingen die passen bij de wensen en ambities van het bedrijf."
    />
    <GridContainer>
      <SimpleTitle>Diversiteit &amp; Inclusie</SimpleTitle>
      <PhotoHero
        title="Diversiteit stuwt innovatie"
        subtitle="Diversiteit en inclusie is een hot-topic in de maatschappij en in organisaties. Een topic die zeker niet nieuw is, maar hernieuwde aandacht krijgt door gebeurtenissen in binnen- en buitenland. Diversiteit op de werkplek heeft de kracht om het gevoel van verbondenheid op te wekken, om de winst te vergroten en het leidt tot meer innovatie. Een team met een hoge mate van diversiteit neemt betere beslissingen en de teams zijn productiever. Nogal wat voordelen om als organisatie niet alleen een passieve doelstelling te formuleren, maar er ook daadwerkelijk iets aan te gaan doen."
        image={
          <StaticImage
            src="../../images/inclusie.jpg"
            alt="Diversiteit en inclusie"
            placeholder="blurred"
            quality={100}
          />
        }
      />
      <Card title="Inclusieve werkcultuur">
        <Paragraph>
          Diversiteit is meer dan gender en huidskleur. Diversiteit staat voor
          verschil, zowel zichtbaar als onzichtbaar. Maar met alleen aandacht
          hebben voor diversiteit op de werkvloer ben je er nog niet. Als we
          spreken over inclusie dan gaat het verder dan integratie of gedogen,
          inclusie is mensen werkelijk betrekken, insluiten en opnemen in de
          groep. Want alleen dan ontstaat er een leerklimaat die ervoor zorgt
          dat de diversiteit ten volle kan worden benut. Dit noemen wij bij de
          Beukelaar Groep een inclusieve werkcultuur. Een werkwijze en cultuur
          waarbij alle aanwezige talenten worden gezien en benut, waar
          verschillende ideeën worden gehoord en waar niemand op basis van zijn
          of haar andersheid impliciet of expliciet wordt buitengesloten.
        </Paragraph>
      </Card>
      <StyledCardsContainer title="Bekijk ons aanbod:">
        <SecondaryCard title="D&amp;I analyse">
          <p>
            Organisaties willen wel, maar weten vaak niet hoe ze aan de slag
            moeten met D&amp;I. De Beukelaar Groep kan jou en je organisatie
            ondersteunen bij de eerste stappen in dit proces. Door middel van
            een organisatieanalyse stellen we een punt nul op en adviseren wij
            jullie over oplossingen die passen bij de wensen en ambities van het
            bedrijf.
          </p>
        </SecondaryCard>
        <SecondaryCard title="D&amp;I workshop">
          <p>
            Aandacht voor het thema D&amp;I zorgt altijd voor de meest
            waardevolle gesprekken in een bedrijf. In een dagdeel faciliteert de
            Beukelaar Groep een levendig gesprek over D&amp;I en helpt om de
            voordelen en moeilijkheden in kaart te brengen.
          </p>
        </SecondaryCard>
        <SecondaryCard title="Praten over D&amp;I">
          <p>
            Binnenkort lanceert de Beukelaar groep een online programma met de
            naam: praten over D&amp;I. Praten over D&amp;I is vaak de eerste
            stap van een manager, medewerker of bestuurder om het beleid omtrent
            D&amp;I van de organisatie aan te kaarten. En deze stap blijkt nog
            niet zo eenvoudig. Vrij snel vervallen deze gesprekken in verwijten
            en oordelen over en weer. Wil jij leren hoe je dit thema op een
            constructieve manier onder de aandacht kunt brengen? Hou ons in de
            gaten, binnenkort verschijnt er een nieuw online programma!
          </p>
        </SecondaryCard>
      </StyledCardsContainer>
      <NavigationDetailCTA>
        <PrimaryLink to="/diensten/organisatieontwikkeling/">
          NAAR ORGANISATIEONTWIKKELING
        </PrimaryLink>
        <PrimaryLink to="/diensten/teamontwikkeling/">
          NAAR TEAMONTWIKKELING
        </PrimaryLink>
        <PrimaryLink to="/diensten/leiderschap/">NAAR LEIDERSCHAP</PrimaryLink>
      </NavigationDetailCTA>
    </GridContainer>
  </>
)

export default DiverseInclusionPage
